import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import saveExpensesGroup from '@/api/receipt/saveExpensesGroup'
import { i18n } from '@/plugins/i18n'

export default function useSaveExpensesGroup() {
  const searchtext = ref('')
  const shop_bankgroup_status_id = ref(1)
  const statusList = ref(basicStatus.data)

  const dataTableList = ref([])
  const loading = ref(false)
  const options = ref({})
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'shop_bankpay_id',
      width: 50,
    },
    {
      text: i18n.t('expense_category'),
      value: 'shop_bankgroup_name',
      width: 150,
    },
    {
      text: i18n.t('status'),
      value: 'shop_bankgroup_status_id',
      width: 250,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center',
      width: 120,
    },
  ])

  const isAddNewSaveExpensesGroup = ref(false)

  const isEditSaveExpensesGroup = ref(false)
  const dataEdit = ref({})

  const isUpdateStatus = ref(false)
  const shop_bankgroup_id = ref('')
  const shop_bankgroup_status_id_update = ref('')

  const { saveExpensesGroupList } = saveExpensesGroup

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    saveExpensesGroupList({
      searchtext: searchtext.value,
      shop_bankgroup_status_id: shop_bankgroup_status_id.value,
    }).then(res => {
      dataTableList.value = res
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }
  watch([searchtext, shop_bankgroup_status_id], () => {
    fetchDataTable()
  })

  return {
    isUpdateStatus,
    shop_bankgroup_id,
    shop_bankgroup_status_id_update,
    isEditSaveExpensesGroup,
    dataEdit,
    searchtext,
    shop_bankgroup_status_id,
    statusList,
    dataTableList,
    loading,
    options,
    columns,
    isAddNewSaveExpensesGroup,
    fetchDataTable,
  }
}
