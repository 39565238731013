<template>
  <div>
    <v-dialog
      v-model="isEditSaveExpensesGroup"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_expense_category') }}
        </v-card-title>
        <v-form
          ref="formEditSaveExpenseGroup"
          @submit.prevent="createSaveExpensesGroup"
        >
          <v-card-text>
            <v-text-field
              v-model="shopName"
              readonly
              outlined
              dense
              :label="$t('clinic_name')"
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.shop_bankgroup_name"
              dense
              :rules="[required]"
              autofocus
              outlined
              :label="$t('expense_category')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-save-expenses-group',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import saveExpensesGroup from '@/api/receipt/saveExpensesGroup'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditSaveExpensesGroup',
    event: 'update:is-edit-save-expenses-group',
  },
  props: {
    isEditSaveExpensesGroup: { type: Boolean, default: false },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditSaveExpenseGroup = ref(null)
    const dataEditLocal = ref({})
    const loading = ref(false)
    const shopName = ref(localStorage.getItem('shop_name'))
    const { saveExpensesGroupUpdate } = saveExpensesGroup

    const createSaveExpensesGroup = () => {
      const isFormValid = formEditSaveExpenseGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      saveExpensesGroupUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-save-expenses-group', false)
        loading.value = false
        emit('onUpdate')
      })
    }
    watch(() => props.dataEdit, val => {
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      formEditSaveExpenseGroup,
      dataEditLocal,
      loading,
      shopName,
      createSaveExpensesGroup,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
