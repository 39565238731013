<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Save-expenses'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link>  {{ $t('expense_category') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewSaveExpensesGroup = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_expense_category') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          small
          @click="isAddNewSaveExpensesGroup =true"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="shop_bankgroup_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.shop_bankpay_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.shop_bankgroup_status_id`]="{ item }">
          <StatusBlock :status="item.shop_bankgroup_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="item.shop_bankgroup_active_id!=1"
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item; isEditSaveExpensesGroup =true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.shop_bankgroup_active_id!=1"
                v-bind="attrs"
                @click="shop_bankgroup_id = item.shop_bankgroup_id;shop_bankgroup_status_id_update=item.shop_bankgroup_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("suspend") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewSaveExpensesGroup
      v-model="isAddNewSaveExpensesGroup"
      @onAdd="fetchDataTable"
    />
    <EditSaveExpensesGroup
      v-model="isEditSaveExpensesGroup"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <UpdateStatusSaveExpensesGroup
      :id="shop_bankgroup_id"
      v-model="isUpdateStatus"
      :status-id="shop_bankgroup_status_id_update"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>

<script>
import {
  mdiArrowLeftBold, mdiPlus, mdiPencilOutline, mdiDeleteOutline,
} from '@mdi/js'
import useSaveExpensesGroup from './useSaveExpensesGroup'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewSaveExpensesGroup from './AddNewSaveExpensesGroup.vue'
import EditSaveExpensesGroup from './EditSaveExpensesGroup.vue'
import UpdateStatusSaveExpensesGroup from './UpdateStatusSaveExpensesGroup.vue'

export default {

  components: {
    StatusBlock,
    AddNewSaveExpensesGroup,
    EditSaveExpensesGroup,
    UpdateStatusSaveExpensesGroup,
  },
  setup() {
    const {
      isUpdateStatus,
      shop_bankgroup_id,
      shop_bankgroup_status_id_update,
      isEditSaveExpensesGroup,
      dataEdit,
      isAddNewSaveExpensesGroup,
      searchtext,
      shop_bankgroup_status_id,
      statusList,
      dataTableList,
      loading,
      options,
      columns,
      fetchDataTable,
    } = useSaveExpensesGroup()

    return {
      isUpdateStatus,
      shop_bankgroup_id,
      shop_bankgroup_status_id_update,
      isEditSaveExpensesGroup,
      dataEdit,
      isAddNewSaveExpensesGroup,
      searchtext,
      shop_bankgroup_status_id,
      statusList,
      dataTableList,
      loading,
      options,
      columns,
      fetchDataTable,
      icons: {
        mdiArrowLeftBold, mdiPlus, mdiPencilOutline, mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
