import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const saveExpensesGroupList = async body => {
  await refreshToken()
  const response = await api
    .post({
      path: 'walletpaygroup/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get save expenses group list err : ', err)

      return []
    })

  return response
}
const saveExpensesGroupAdd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'walletpaygroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add save expenses group err : ', err)

      return {}
    })

  return response
}
const saveExpensesGroupUpdate = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'walletpaygroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update save expenses group err : ', err)

      return {}
    })

  return response
}
const saveExpensesGroupStatusUpdate = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'walletpaygroup/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update status save expenses group err : ', err)

      return {}
    })

  return response
}
export default {
  saveExpensesGroupList, saveExpensesGroupAdd, saveExpensesGroupUpdate, saveExpensesGroupStatusUpdate,
}
